<template>
    <div class="manage_box">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <span style="font-size: 16px">概况</span>
                    <span class="zxiaohao">
                        <i
                            class="iconfont icon-ico_caiwuguanli_xiaohaoxiangmuchaxun"
                        ></i>
                        {{ dataChoice }}总消耗
                    </span>
                    <p style="color: #2370eb">
                        {{ allCostAmount.toFixed(2) }}元
                    </p>
                    <span class="qiyong">
                        <i class="iconfont icon-tongguo"></i>
                        账户启用
                    </span>
                    <p style="color: #3538a3">
                        {{ allLaunchBriefProductRead }}个
                    </p>
                    <span class="tingyong">
                        <i class="iconfont icon-shanchu1"></i>
                        账户停用
                    </span>
                    <p style="color: #e41f3f">
                        {{
                            allLaunchBriefProduct - allLaunchBriefProductRead
                        }}个
                    </p>
                </div>
            </div>
        </div>
        <div
            class="search-box"
            style="flex: 1; margin-bottom: 0; background: #f5f5f5"
        >
            <div class="search_one" style="background: #fff">
                <div class="left">
                    <span style="font-size: 16px">
                        <i
                            class="el-icon-s-data"
                            style="background: #fbbe60; color: #fff"
                        ></i>
                        线索简报
                    </span>

                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="'刷新时间：' + refreshTime"
                        placement="bottom"
                    >
                        <el-button
                            type="primary"
                            class="hoverBtn"
                            size="small"
                            @click="refresh"
                        >
                            <i class="iconfont icon-shuaxin1"></i>
                            拉取全部数据
                        </el-button>
                    </el-tooltip>
                </div>
                <p>
                    <span>时间</span>
                    <el-radio-group v-model="dataChoice" size="small">
                        <el-radio-button
                            label="今日"
                            @click.native.prevent="clickitem('今日')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本周"
                            @click.native.prevent="clickitem('本周')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本月"
                            @click.native.prevent="clickitem('本月')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        size="small"
                        v-model="timeDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        :clearable="false"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 240px; margin: 0 15px"
                        @change="expireTime"
                    >
                    </el-date-picker>
                </p>
            </div>
            <div class="detail_list" v-if="productList.length > 0">
                <div
                    class="detail_item"
                    v-for="(item, index) in productList"
                    :key="item.id"
                >
                    <div class="bg">
                        <span
                            :style="{
                                backgroundColor:
                                    index % 7 == 0
                                        ? '#2370EB'
                                        : index % 7 == 1
                                        ? '#FA6400'
                                        : index % 7 == 2
                                        ? '#D0021B'
                                        : index % 7 == 3
                                        ? '#FBBE60'
                                        : index % 7 == 4
                                        ? '#5B76F9'
                                        : index % 7 == 5
                                        ? '#51BEE9'
                                        : index % 7 == 6
                                        ? '#FF7E63'
                                        : '',
                            }"
                            >{{ item.productName }}</span
                        >
                        <span class="btn" @click="showDetail(item, index)"
                            >详情
                            <i
                                :class="
                                    item.tag
                                        ? 'el-icon-arrow-up'
                                        : 'el-icon-arrow-down'
                                "
                            ></i>
                        </span>
                        <div class="main_box">
                            <div class="main"></div>
                            <div class="center_data">
                                <div class="inside_circle">
                                    <p>
                                        <span>{{ item.costAmount }}元</span>
                                        {{ dataChoice }}消耗 <br />
                                    </p>
                                    <span v-if="dataChoice == '今日'"
                                        >较昨日

                                        <i
                                            style="font-style: normal"
                                            v-if="
                                                yesterdayProductList.length >
                                                    0 &&
                                                $tableDataHandle.getYesterdayData(
                                                    yesterdayProductList,
                                                    item
                                                ) &&
                                                $tableDataHandle.getYesterdayData(
                                                    yesterdayProductList,
                                                    item
                                                ).costAmount &&
                                                dataChoice == '今日'
                                            "
                                            :class="
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).costAmount,
                                                    item.costAmount
                                                )[0]
                                                    ? 'just'
                                                    : 'negative'
                                            "
                                            >{{
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).costAmount,
                                                    item.costAmount
                                                )[1]
                                            }}</i
                                        >
                                        <i
                                            v-else-if="dataChoice == '今日'"
                                            style="font-style: normal"
                                            class="just"
                                        >
                                            {{ '- -' }}
                                        </i>
                                    </span>
                                </div>
                            </div>
                            <div class="clue_data_box">
                                <div class="clue_data_item">
                                    <div>
                                        <i
                                            class="iconfont icon-quedingzhanshi"
                                        ></i>
                                        <p>
                                            <span class="clue_data"
                                                ><em>展现</em>
                                                {{ item.views }}次</span
                                            >
                                            <span
                                                :class="
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).views,
                                                        item.views
                                                    )[0]
                                                        ? 'just'
                                                        : 'negative'
                                                "
                                                v-if="
                                                    yesterdayProductList.length >
                                                        0 &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ) &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).views &&
                                                    dataChoice == '今日'
                                                "
                                                ><em>较昨日</em>
                                                {{
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).views,
                                                        item.views
                                                    )[1]
                                                }}</span
                                            >
                                            <span
                                                v-else-if="dataChoice == '今日'"
                                                class="just"
                                                ><em>较昨日</em
                                                >{{ '- -' }}</span
                                            >
                                        </p>
                                    </div>

                                    <div>
                                        <i
                                            class="iconfont icon-icon-dianjifangkuang"
                                        ></i>
                                        <p>
                                            <span class="clue_data"
                                                ><em>点击</em
                                                >{{ item.clicks }}次</span
                                            >
                                            <span
                                                :class="
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).clicks,
                                                        item.clicks
                                                    )[0]
                                                        ? 'just'
                                                        : 'negative'
                                                "
                                                v-if="
                                                    yesterdayProductList.length >
                                                        0 &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ) &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).clicks &&
                                                    dataChoice == '今日'
                                                "
                                                ><em>较昨日</em>
                                                {{
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).clicks,
                                                        item.clicks
                                                    )[1]
                                                }}</span
                                            >
                                            <span
                                                v-else-if="dataChoice == '今日'"
                                                class="just"
                                                ><em>较昨日</em
                                                >{{ '- -' }}</span
                                            >
                                        </p>
                                    </div>
                                    <div>
                                        <i
                                            class="iconfont icon-xitongtuisong"
                                        ></i>
                                        <p>
                                            <span
                                                class="clue_data"
                                                v-if="
                                                    item.views > 0 &&
                                                    item.clicks > 0
                                                "
                                                ><em>点击率</em
                                                >{{
                                                    (
                                                        (item.clicks /
                                                            item.views) *
                                                        100
                                                    ).toFixed(2)
                                                }}%</span
                                            >
                                            <span v-else class="clue_data"
                                                ><em>点击率</em>0%</span
                                            >
                                            <span
                                                :class="
                                                    $tableDataHandle.launchRatio(
                                                        (yesterdayProductList[
                                                            index
                                                        ].clicks /
                                                            yesterdayProductList[
                                                                index
                                                            ].views) *
                                                            100,
                                                        (item.clicks /
                                                            item.views) *
                                                            100
                                                    )[0]
                                                        ? 'just'
                                                        : 'negative'
                                                "
                                                v-if="
                                                    yesterdayProductList.length >
                                                        0 &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ) &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).id &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).clicks &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).views &&
                                                    dataChoice == '今日'
                                                "
                                                ><em>较昨日</em>

                                                {{
                                                    $tableDataHandle.launchRatio(
                                                        ($tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).clicks /
                                                            $tableDataHandle.getYesterdayData(
                                                                yesterdayProductList,
                                                                item
                                                            ).views) *
                                                            100,
                                                        (item.clicks /
                                                            item.views) *
                                                            100
                                                    )[1]
                                                }}
                                            </span>
                                            <span
                                                v-else-if="dataChoice == '今日'"
                                                class="just"
                                                ><em>较昨日</em
                                                >{{ '- -' }}</span
                                            >
                                        </p>
                                    </div>
                                    <div>
                                        <i
                                            class="iconfont icon-shuyi_jinqian-jiage"
                                        ></i>
                                        <p>
                                            <span
                                                class="clue_data"
                                                v-if="
                                                    item.costAmount > 0 &&
                                                    item.clicks > 0
                                                "
                                                ><em>点击均价</em
                                                >{{
                                                    (
                                                        item.costAmount /
                                                        item.clicks
                                                    ).toFixed(2)
                                                }}元</span
                                            >
                                            <span v-else class="clue_data"
                                                ><em>点击均价</em>0元</span
                                            >
                                            <span
                                                :class="
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).costAmount /
                                                            $tableDataHandle.getYesterdayData(
                                                                yesterdayProductList,
                                                                item
                                                            ).clicks,
                                                        item.costAmount /
                                                            item.clicks
                                                    )[0]
                                                        ? 'just'
                                                        : 'negative'
                                                "
                                                v-if="
                                                    yesterdayProductList.length >
                                                        0 &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ) &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).costAmount &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).clicks &&
                                                    dataChoice == '今日'
                                                "
                                                ><em>较昨日</em>

                                                {{
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).costAmount /
                                                            $tableDataHandle.getYesterdayData(
                                                                yesterdayProductList,
                                                                item
                                                            ).clicks,
                                                        item.costAmount /
                                                            item.clicks
                                                    )[1]
                                                }}
                                            </span>
                                            <span
                                                v-else-if="dataChoice == '今日'"
                                                class="just"
                                                ><em>较昨日</em>
                                                {{ '- -' }}</span
                                            >
                                        </p>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="clue_data_item">
                                    <div>
                                        <i class="iconfont icon-xiansuo1"></i>
                                        <p>
                                            <span class="clue_data"
                                                ><em>产生线索</em
                                                >{{ item.generateLine }}个</span
                                            >
                                            <span
                                                :class="
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).generateLine,
                                                        item.generateLine
                                                    )[0]
                                                        ? 'just'
                                                        : 'negative'
                                                "
                                                v-if="
                                                    yesterdayProductList.length >
                                                        0 &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ) &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).generateLine &&
                                                    dataChoice == '今日'
                                                "
                                                ><em>较昨日</em>
                                                {{
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).generateLine,
                                                        item.generateLine
                                                    )[1]
                                                }}</span
                                            >
                                            <span
                                                v-else-if="dataChoice == '今日'"
                                                class="just"
                                                ><em>较昨日</em
                                                >{{ '- -' }}</span
                                            >
                                        </p>
                                    </div>
                                    <div>
                                        <i class="iconfont icon-icon03"></i>
                                        <p>
                                            <span
                                                class="clue_data"
                                                v-if="
                                                    item.costAmount > 0 &&
                                                    item.generateLine > 0
                                                "
                                                ><em>线索成本</em
                                                >{{
                                                    (
                                                        item.costAmount /
                                                        item.generateLine
                                                    ).toFixed(2)
                                                }}元</span
                                            >
                                            <span v-else class="clue_data"
                                                ><em>线索成本</em>- -</span
                                            >
                                            <span
                                                :class="
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).costAmount /
                                                            $tableDataHandle.getYesterdayData(
                                                                yesterdayProductList,
                                                                item
                                                            ).generateLine,
                                                        item.costAmount /
                                                            item.generateLine
                                                    )[0]
                                                        ? 'just'
                                                        : 'negative'
                                                "
                                                v-if="
                                                    yesterdayProductList.length >
                                                        0 &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ) &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).costAmount &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).generateLine &&
                                                    dataChoice == '今日' &&
                                                    item.generateLine
                                                "
                                                ><em>较昨日</em>

                                                {{
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).costAmount /
                                                            $tableDataHandle.getYesterdayData(
                                                                yesterdayProductList,
                                                                item
                                                            ).generateLine,
                                                        item.costAmount /
                                                            item.generateLine
                                                    )[1]
                                                }}
                                            </span>
                                            <span
                                                v-else-if="dataChoice == '今日'"
                                                class="just"
                                                ><em>较昨日</em
                                                >{{ '- -' }}</span
                                            >
                                        </p>
                                    </div>
                                    <div>
                                        <i
                                            class="iconfont icon-xiansuo-daoyinxuanzhong"
                                        ></i>
                                        <p>
                                            <span class="clue_data"
                                                ><em>有效线索</em
                                                >{{ item.validLine }}个</span
                                            >
                                            <span
                                                :class="
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).validLine,
                                                        item.validLine
                                                    )[0]
                                                        ? 'just'
                                                        : 'negative'
                                                "
                                                v-if="
                                                    yesterdayProductList.length >
                                                        0 &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ) &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).validLine &&
                                                    dataChoice == '今日'
                                                "
                                                ><em>较昨日</em>
                                                {{
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).validLine,
                                                        item.validLine
                                                    )[1]
                                                }}</span
                                            >
                                            <span
                                                v-else-if="dataChoice == '今日'"
                                                class="just"
                                                ><em>较昨日</em>
                                                {{ '- -' }}</span
                                            >
                                        </p>
                                    </div>
                                    <div>
                                        <i
                                            class="iconfont icon-chukoutuishui_huanhuichengben"
                                        ></i>
                                        <p>
                                            <span
                                                class="clue_data"
                                                v-if="
                                                    item.costAmount > 0 &&
                                                    item.validLine > 0
                                                "
                                                ><em>有效成本</em
                                                >{{
                                                    (
                                                        item.costAmount /
                                                        item.validLine
                                                    ).toFixed(2)
                                                }}元</span
                                            >
                                            <span v-else class="clue_data"
                                                ><em>有效成本</em>- -</span
                                            >
                                            <span
                                                :class="
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).costAmount /
                                                            $tableDataHandle.getYesterdayData(
                                                                yesterdayProductList,
                                                                item
                                                            ).validLine,
                                                        item.costAmount /
                                                            item.validLine
                                                    )[0]
                                                        ? 'just'
                                                        : 'negative'
                                                "
                                                v-if="
                                                    yesterdayProductList.length >
                                                        0 &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ) &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).costAmount &&
                                                    $tableDataHandle.getYesterdayData(
                                                        yesterdayProductList,
                                                        item
                                                    ).validLine &&
                                                    dataChoice == '今日' &&
                                                    item.validLine
                                                "
                                                ><em>较昨日</em>

                                                {{
                                                    $tableDataHandle.launchRatio(
                                                        $tableDataHandle.getYesterdayData(
                                                            yesterdayProductList,
                                                            item
                                                        ).costAmount /
                                                            $tableDataHandle.getYesterdayData(
                                                                yesterdayProductList,
                                                                item
                                                            ).validLine,
                                                        item.costAmount /
                                                            item.validLine
                                                    )[1]
                                                }}
                                            </span>
                                            <span
                                                v-else-if="dataChoice == '今日'"
                                                class="just"
                                                ><em>较昨日</em
                                                >{{ '- -' }}</span
                                            >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="channel_list" v-show="item.tag">
                        <div
                            v-for="(itm, idx) in item.channelDataList"
                            :key="itm.id"
                        >
                            <div class="channel_item">
                                <span>{{
                                    $tableDataHandle.launchType(itm.channelType)
                                }}</span>
                                <div class="first">
                                    <img
                                        v-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            $tableDataHandle.launchRatio(
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).costAmount,
                                                itm.costAmount
                                            )[0]
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            dataChoice == '今日'
                                        "
                                        src="@/assets/down.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            (item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length == 0) ||
                                            dataChoice != '今日'
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <p>
                                        <span class="clue_data"
                                            ><em>{{ dataChoice }}消耗</em>
                                            {{ itm.costAmount }}元</span
                                        >
                                        <span
                                            :class="
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).costAmount,
                                                    itm.costAmount
                                                )[0]
                                                    ? 'just'
                                                    : 'negative'
                                            "
                                            v-if="
                                                item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length > 0 &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ) &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).costAmount &&
                                                dataChoice == '今日'
                                            "
                                            ><em>较昨日</em>
                                            {{
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).costAmount,
                                                    itm.costAmount
                                                )[1]
                                            }}</span
                                        >
                                        <span
                                            v-else-if="dataChoice == '今日'"
                                            class="just"
                                            ><em>较昨日</em>{{ '- -' }}</span
                                        >
                                    </p>
                                </div>
                                <div>
                                    <img
                                        v-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            $tableDataHandle.launchRatio(
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).views,
                                                itm.views
                                            )[0]
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            dataChoice == '今日'
                                        "
                                        src="@/assets/down.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            (item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length == 0) ||
                                            dataChoice != '今日'
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <p>
                                        <span class="clue_data"
                                            ><em>展现</em
                                            >{{ itm.views }}次</span
                                        >
                                        <span
                                            :class="
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).views,
                                                    itm.views
                                                )[0]
                                                    ? 'just'
                                                    : 'negative'
                                            "
                                            v-if="
                                                item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length > 0 &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ) &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).views &&
                                                dataChoice == '今日'
                                            "
                                            ><em>较昨日</em>
                                            {{
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).views,
                                                    itm.views
                                                )[1]
                                            }}</span
                                        >
                                        <span
                                            v-else-if="dataChoice == '今日'"
                                            class="just"
                                            ><em>较昨日</em>{{ '- -' }}</span
                                        >
                                    </p>
                                </div>
                                <div>
                                    <img
                                        v-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            $tableDataHandle.launchRatio(
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).clicks,
                                                itm.clicks
                                            )[0]
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            dataChoice == '今日'
                                        "
                                        src="@/assets/down.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            (item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length == 0) ||
                                            dataChoice != '今日'
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <p>
                                        <span class="clue_data"
                                            ><em>点击</em
                                            >{{ itm.clicks }}次</span
                                        >
                                        <span
                                            :class="
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).clicks,
                                                    itm.clicks
                                                )[0]
                                                    ? 'just'
                                                    : 'negative'
                                            "
                                            v-if="
                                                item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length > 0 &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ) &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).clicks &&
                                                dataChoice == '今日'
                                            "
                                            ><em>较昨日</em>
                                            {{
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).clicks,
                                                    itm.clicks
                                                )[1]
                                            }}</span
                                        >
                                        <span
                                            v-else-if="dataChoice == '今日'"
                                            class="just"
                                            ><em>较昨日</em>{{ '- -' }}</span
                                        >
                                    </p>
                                </div>
                                <div>
                                    <img
                                        v-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            $tableDataHandle.launchRatio(
                                                ($tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).clicks /
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).views) *
                                                    100,
                                                (itm.clicks / itm.views) * 100
                                            )[0]
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            dataChoice == '今日'
                                        "
                                        src="@/assets/down.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            (item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length == 0) ||
                                            dataChoice != '今日'
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <p>
                                        <span
                                            class="clue_data"
                                            v-if="
                                                itm.views > 0 && itm.clicks > 0
                                            "
                                            ><em>点击率</em>
                                            {{
                                                (
                                                    (itm.clicks / itm.views) *
                                                    100
                                                ).toFixed(2)
                                            }}%</span
                                        >
                                        <span v-else><em>点击率</em>0%</span>
                                        <span
                                            :class="
                                                $tableDataHandle.launchRatio(
                                                    ($tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).clicks /
                                                        $tableDataHandle.getYesterdayData(
                                                            item.yesterdayChannelDataList,
                                                            itm
                                                        ).views) *
                                                        100,
                                                    (itm.clicks / itm.views) *
                                                        100
                                                )[0]
                                                    ? 'just'
                                                    : 'negative'
                                            "
                                            v-if="
                                                item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length > 0 &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ) &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).views &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).clicks &&
                                                dataChoice == '今日'
                                            "
                                            ><em>较昨日</em>

                                            {{
                                                $tableDataHandle.launchRatio(
                                                    ($tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).clicks /
                                                        $tableDataHandle.getYesterdayData(
                                                            item.yesterdayChannelDataList,
                                                            itm
                                                        ).views) *
                                                        100,
                                                    (itm.clicks / itm.views) *
                                                        100
                                                )[1]
                                            }}
                                        </span>
                                        <span
                                            v-else-if="dataChoice == '今日'"
                                            class="just"
                                            ><em>较昨日</em>{{ '- -' }}</span
                                        >
                                    </p>
                                </div>
                                <div>
                                    <img
                                        v-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            $tableDataHandle.launchRatio(
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).costAmount /
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).clicks,
                                                itm.costAmount / itm.clicks
                                            )[0]
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            item.yesterdayChannelDataList &&
                                            item.yesterdayChannelDataList
                                                .length > 0 &&
                                            dataChoice == '今日'
                                        "
                                        src="@/assets/down.png"
                                        alt=""
                                    />
                                    <img
                                        v-else-if="
                                            (item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length == 0) ||
                                            dataChoice != '今日'
                                        "
                                        src="@/assets/up.png"
                                        alt=""
                                    />
                                    <p>
                                        <span
                                            class="clue_data"
                                            v-if="
                                                itm.costAmount > 0 &&
                                                itm.clicks > 0
                                            "
                                            ><em>点击均价</em
                                            >{{
                                                (
                                                    itm.costAmount / itm.clicks
                                                ).toFixed(2)
                                            }}元</span
                                        >
                                        <span v-else class="clue_data"
                                            ><em>点击均价</em>0元</span
                                        >
                                        <span
                                            :class="
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).costAmount /
                                                        $tableDataHandle.getYesterdayData(
                                                            item.yesterdayChannelDataList,
                                                            itm
                                                        ).clicks,
                                                    itm.costAmount / itm.clicks
                                                )[0]
                                                    ? 'just'
                                                    : 'negative'
                                            "
                                            v-if="
                                                item.yesterdayChannelDataList &&
                                                item.yesterdayChannelDataList
                                                    .length > 0 &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ) &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).costAmount &&
                                                $tableDataHandle.getYesterdayData(
                                                    item.yesterdayChannelDataList,
                                                    itm
                                                ).clicks &&
                                                dataChoice == '今日'
                                            "
                                            ><em>较昨日</em>

                                            {{
                                                $tableDataHandle.launchRatio(
                                                    $tableDataHandle.getYesterdayData(
                                                        item.yesterdayChannelDataList,
                                                        itm
                                                    ).costAmount /
                                                        $tableDataHandle.getYesterdayData(
                                                            item.yesterdayChannelDataList,
                                                            itm
                                                        ).clicks,
                                                    itm.costAmount / itm.clicks
                                                )[1]
                                            }}
                                        </span>
                                        <span
                                            v-else-if="dataChoice == '今日'"
                                            class="just"
                                            ><em>较昨日</em> {{ '- -' }}</span
                                        >
                                    </p>
                                </div>
                                <el-button
                                    type="primary"
                                    @click="showTable(itm, index, idx)"
                                >
                                    列表
                                </el-button>
                            </div>
                            <div class="table_box" v-show="itm.tag">
                                <Table
                                    :ref="'table' + (index + 1) + '' + idx"
                                    @updateAmount="
                                        (row) => {
                                            updateAmount(row, index, idx);
                                        }
                                    "
                                ></Table>
                                <div
                                    class="page_box"
                                    v-if="
                                        totalObj[
                                            'total' + (index + 1) + '' + idx
                                        ] > 5
                                    "
                                >
                                    <el-pagination
                                        @current-change="
                                            (val) => {
                                                handleCurrentChange(
                                                    val,
                                                    itm,
                                                    index,
                                                    idx
                                                );
                                            }
                                        "
                                        @size-change="
                                            (val) => {
                                                handleSizeChange(
                                                    val,
                                                    itm,
                                                    index,
                                                    idx
                                                );
                                            }
                                        "
                                        :current-page="
                                            currentPageObj[
                                                'currentPage' +
                                                    (index + 1) +
                                                    '' +
                                                    idx
                                            ]
                                        "
                                        :page-size="
                                            pagesizeObj[
                                                'pageSize' +
                                                    (index + 1) +
                                                    '' +
                                                    idx
                                            ]
                                        "
                                        :page-sizes="[5, 10, 20]"
                                        layout="total,sizes,prev, pager, next, jumper"
                                        :total="
                                            totalObj[
                                                'total' + (index + 1) + '' + idx
                                            ]
                                        "
                                        :ref="
                                            'pagination' +
                                            (index + 1) +
                                            '' +
                                            idx
                                        "
                                    ></el-pagination>
                                    <el-button
                                        type="primary"
                                        @click="hideTable(itm, index, idx)"
                                    >
                                        收起
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-title" v-show="productList.length > 0">
            <div class="search-one">
                <div class="left">
                    <span
                        style="
                            margin: 0 16px;
                            font-size: 14px;
                            font-weight: 600;
                        "
                        >产品名称</span
                    >
                    <el-select
                        style="width: 180px"
                        v-model="launchDO.productId"
                        size="small"
                        :disabled="isEdit"
                        placeholder="请选择"
                        clearable
                        @change="getAllTableData2"
                    >
                        <el-option
                            v-for="item in launchProductTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span
                        style="
                            margin: 0 16px;
                            font-size: 14px;
                            font-weight: 600;
                        "
                        >投放渠道</span
                    >
                    <el-select
                        style="width: 180px"
                        v-model="launchDO.channelType"
                        size="small"
                        :disabled="isEdit"
                        placeholder="请选择"
                        clearable
                        @change="getAllTableData2"
                    >
                        <el-option
                            v-for="item in $searchForm.launchType()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="table" v-show="productList.length > 0">
            <AllTable
                ref="allTable"
                @totalNum="totalNum"
                @updateAmount="
                    (row) => {
                        updateAmount(row, -1, -1);
                    }
                "
            ></AllTable>
        </div>
        <div class="page-box" v-show="productList.length > 0">
            <el-pagination
                @current-change="allHandleCurrentChange"
                @size-change="allHandleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[5, 10, 20]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import {
    totalData,
    launchProductList,
    launchAmountUpdate,
} from '@/api/user/launch/manage.js';
import {
    launchDataProductList,
    launchDataChannelList,
    launchDataTableList,
    refresh,
} from '@/api/user/launch/data.js';
import Table from '../table/table.vue';
import AllTable from '../table/allTable.vue';
import * as echarts from 'echarts';
export default {
    name: '',
    props: {},
    data() {
        return {
            launchDO: {},
            currentPage: 1,
            pagesize: 5,
            total: 0,
            searchVal: {},
            btnP: {},
            currentPageObj: {},
            pagesizeObj: {},
            totalObj: {},
            dataChoice: '今日',
            dataChoice2: '',
            timeDate: null,
            allCostAmount: 0,
            direction: 'rtl',
            drawerAdd: false,
            refreshTime: '',
            drawerUpdate: false,
            dialogAddPro: false,
            tag: false,
            declareChoice: '全部',
            tag2: false,
            isEdit: false,
            productList: [],
            launchProductTypeList: [],
            yesterdayProductList: [],
            channelDataList: [],
            yesterdayChannelDataList: [],
            allLaunchBriefProduct: 0,
            allLaunchBriefProductRead: 0,
        };
    },
    components: {
        Table,
        AllTable,
    },
    created() {},
    async mounted() {
        await this.totalData();
        await this.launchProductList();
        await this.getAllTableData();
        await this.getData();
        this.dataChoice2 = '昨日';
        await this.getData();
    },
    methods: {
        async refresh() {
            let data = {
                param: {},
            };
            let res = await refresh(data);
            if (res.code == 200) {
                this.dataChoice2 = '';
                await this.totalData();
                await this.launchProductList();
                await this.getAllTableData();
                await this.getData();
                this.dataChoice2 = '昨日';
                await this.getData();
            }
        },
        getData() {
            // this.productList.forEach((item, index) => {
            this.productList = [];
            let data = {
                param: {},
            };
            let date = new Date();

            if (this.dataChoice == '今日') {
                data.param.startDate = this.$searchTime.getNextDate(date, 0);
                data.param.endDate = this.$searchTime.getNextDate(date, 0);
                // data.param.startDate = "2022-02-08";
                // data.param.endDate = "2022-02-08";
            }
            if (this.dataChoice == '本周') {
                data.param.startDate =
                    this.$searchTime.DateUtil.getStartDayOfWeek();
                data.param.endDate =
                    this.$searchTime.DateUtil.getEndDayOfWeek();
            }
            if (this.dataChoice == '本月') {
                data.param.startDate =
                    this.$searchTime.DateUtil.getStartDayOfMonth();
                data.param.endDate =
                    this.$searchTime.DateUtil.getEndDayOfMonth();
            }
            if (this.dataChoice2 == '昨日') {
                data.param.startDate = this.$searchTime.getNextDate(date, 1);
                data.param.endDate = this.$searchTime.getNextDate(date, 1);
                // data.param.startDate = "2022-02-07";
                // data.param.endDate = "2022-02-07";
            }
            if (this.timeDate) {
                data.param.startDate = this.timeDate[0];
                data.param.endDate = this.timeDate[1];
            }
            launchDataProductList(data).then((res) => {
                if (res.code == 200) {
                    res.data.launchDataBOList.forEach((item) => {
                        item.tag = false;
                    });

                    if (
                        data.param.startDate ==
                            this.$searchTime.getNextDate(date, 1) &&
                        data.param.endDate ==
                            this.$searchTime.getNextDate(date, 1) &&
                        !this.timeDate
                    ) {
                        this.yesterdayProductList = res.data.launchDataBOList;
                    } else {
                        this.productList = res.data.launchDataBOList;
                        // console.log(this.productList);
                        this.allCostAmount = 0;
                        this.productList.forEach((item) => {
                            this.allCostAmount += item.costAmount;
                        });
                    }

                    this.refreshTime = res.data.pullTime || '- -';
                    if (this.productList.length > 0) {
                        this.initEChats();
                    }
                }
            });
        },
        initEChats() {
            this.$nextTick(() => {
                var chartDom = document.getElementsByClassName('main');
                for (let i = 0; i < chartDom.length; i++) {
                    var myChart = echarts.init(chartDom[i]);
                    var option = {
                        series: [
                            {
                                name: 'Access From',
                                type: 'pie',
                                radius: ['70%', '80%'],
                                // avoidLabelOverlap: false,
                                itemStyle: {
                                    borderRadius: 2,
                                    borderColor: '#fff',
                                    borderWidth: 1,
                                },
                                label: {
                                    show: false,
                                    position: 'center',
                                },

                                // emphasis: {
                                //   label: {
                                //     show: true,
                                //     fontSize: "10",
                                //     fontWeight: "bold",
                                //   },
                                // },
                                data: [
                                    {
                                        value:
                                            this.yesterdayProductList[i] &&
                                            this.dataChoice == '今日'
                                                ? this.yesterdayProductList[i]
                                                      .costAmount
                                                : 0,
                                        name: '昨日消耗',
                                    },
                                    {
                                        value: this.productList[i]
                                            ? this.productList[i].costAmount
                                            : 0,
                                        name: this.dataChoice + '消耗',
                                    },
                                ],
                            },
                        ],
                        color: ['#F5B501', '#5B76F9'],
                    };

                    option && myChart.setOption(option);
                }
            });
        },
        getChannelData(id, index) {
            let data = {
                param: {
                    productId: id,
                },
            };
            let date = new Date();
            if (this.dataChoice == '今日') {
                data.param.startDate = this.$searchTime.getNextDate(date, 0);
                data.param.endDate = this.$searchTime.getNextDate(date, 0);
                // data.param.startDate = "2022-02-08";
                // data.param.endDate = "2022-02-08";
            }
            if (this.dataChoice == '本周') {
                data.param.startDate =
                    this.$searchTime.DateUtil.getStartDayOfWeek();
                data.param.endDate =
                    this.$searchTime.DateUtil.getEndDayOfWeek();
            }
            if (this.dataChoice == '本月') {
                data.param.startDate =
                    this.$searchTime.DateUtil.getStartDayOfMonth();
                data.param.endDate =
                    this.$searchTime.DateUtil.getEndDayOfMonth();
            }
            if (this.dataChoice2 == '昨日') {
                data.param.startDate = this.$searchTime.getNextDate(date, 1);
                data.param.endDate = this.$searchTime.getNextDate(date, 1);
                // data.param.startDate = "2022-02-07";
                // data.param.endDate = "2022-02-07";
            }
            if (this.timeDate) {
                data.param.startDate = this.timeDate[0];
                data.param.endDate = this.timeDate[1];
            }
            launchDataChannelList(data).then((res) => {
                // // console.log(res);
                if (res.code == 200) {
                    res.data.forEach((item) => {
                        item.tag = false;
                    });
                    if (
                        data.param.startDate ==
                            this.$searchTime.getNextDate(date, 1) &&
                        data.param.endDate ==
                            this.$searchTime.getNextDate(date, 1) &&
                        !this.timeDate
                    ) {
                        this.productList[index].yesterdayChannelDataList =
                            res.data;
                    } else {
                        this.productList[index].channelDataList = res.data;
                    }
                    // console.log(this.productList[index], this.dataChoice2);
                    this.productList = JSON.parse(
                        JSON.stringify(this.productList)
                    );
                }
            });
        },
        async clickitem(e) {
            this.dataChoice = e;
            this.timeDate = null;
            this.currentPage = 1;
            if (e == '今日') {
                this.dataChoice2 = '昨日';
                await this.getData();
                this.dataChoice2 = '';
                await this.getData();
                await this.getAllTableData();
                // this.totalData();
            } else {
                this.dataChoice2 = '';
                await this.getData();
                await this.getAllTableData();
                // this.totalData();
            }
        },
        async showDetail(item, index) {
            if (!this.productList[index].tag) {
                if (this.dataChoice == '今日') {
                    this.dataChoice2 = '昨日';
                    await this.getChannelData(item.productId, index);
                }
                this.dataChoice2 = '';
                await this.getChannelData(item.productId, index);
            }
            this.productList[index].tag = !this.productList[index].tag;
        },
        async showTable(itm, index, idx) {
            this.getLaunchDataTableList(itm, index, idx);
            this.productList[index].channelDataList[idx].tag =
                !this.productList[index].channelDataList[idx].tag;
        },
        hideTable(itm, index, idx) {
            this.productList[index].channelDataList[idx].tag =
                !this.productList[index].channelDataList[idx].tag;
        },
        getLaunchDataTableList(itm, index, idx) {
            this.$set(
                this.currentPageObj,
                'currentPage' + (index + 1) + '' + idx,
                this.currentPageObj['currentPage' + (index + 1) + '' + idx] || 1
            );
            this.$set(
                this.pagesizeObj,
                'pageSize' + (index + 1) + '' + idx,
                this.pagesizeObj['pageSize' + (index + 1) + '' + idx] || 5
            );
            let data = {
                param: {
                    productId: itm.productId,
                    channelType: itm.channelType,
                },
                pageSize: this.pagesizeObj['pageSize' + (index + 1) + '' + idx],
                pageNum:
                    this.currentPageObj['currentPage' + (index + 1) + '' + idx],
            };
            let date = new Date();
            if (this.dataChoice == '今日') {
                data.param.startDate = this.$searchTime.getNextDate(date, 0);
                data.param.endDate = this.$searchTime.getNextDate(date, 0);
                // data.param.startDate = "2022-02-08";
                // data.param.endDate = "2022-02-08";
            }
            if (this.dataChoice == '本周') {
                data.param.startDate =
                    this.$searchTime.DateUtil.getStartDayOfWeek();
                data.param.endDate =
                    this.$searchTime.DateUtil.getEndDayOfWeek();
            }
            if (this.dataChoice == '本月') {
                data.param.startDate =
                    this.$searchTime.DateUtil.getStartDayOfMonth();
                data.param.endDate =
                    this.$searchTime.DateUtil.getEndDayOfMonth();
            }
            if (this.timeDate) {
                data.param.startDate = this.timeDate[0];
                data.param.endDate = this.timeDate[1];
            }
            launchDataTableList(data).then((res) => {
                if (res.code == 200) {
                    this.$refs['table' + (index + 1) + '' + idx][0].getData(
                        res.data.list,
                        this.dataChoice
                    );
                    this.$set(
                        this.totalObj,
                        'total' + (index + 1) + '' + idx,
                        res.data.total
                    );
                }
            });
        },
        // 更新余额
        updateAmount(row, index, idx) {
            let data = {
                param: {},
            };
            launchAmountUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    if (index == -1) {
                        this.getAllTableData();
                    } else {
                        this.getLaunchDataTableList(row, index, idx);
                    }
                }
            });
        },
        getAllTableData2() {
            this.currentPage = 1;
            this.pagesize = 5;
            this.getAllTableData();
        },
        getAllTableData() {
            this.$refs.allTable.getData(
                this.launchDO,
                this.currentPage,
                this.pagesize,
                this.dataChoice,
                this.dataChoice2,
                this.timeDate
            );
        },
        totalNum(data) {
            this.total = data;
        },
        allHandleCurrentChange(val) {
            this.dataChoice2 = '';
            this.currentPage = val;
            this.getAllTableData();
        },
        allHandleSizeChange(val) {
            this.pagesize = val;
            this.currentPage = 1;
            this.dataChoice2 = '';
            this.getAllTableData();
        },
        // 投放产品列表
        launchProductList() {
            let data = {
                param: {},
            };
            launchProductList(data).then((res) => {
                if (res.code == 200) {
                    this.launchProductTypeList = res.data.list;
                }
            });
        },
        // 分页
        handleCurrentChange(val, itm, index, idx) {
            this.currentPageObj['currentPage' + (index + 1) + '' + idx] = val;
            this.getLaunchDataTableList(itm, index, idx);
        },
        handleSizeChange(val, itm, index, idx) {
            this.pagesizeObj['pageSize' + (index + 1) + '' + idx] = val;
            this.getLaunchDataTableList(itm, index, idx);
        },
        // 时间输入框变化
        async expireTime(val) {
            this.dataChoice = '';
            this.dataChoice2 = '';
            this.currentPage = 1;
            await this.getData();
            await this.getAllTableData();
        },
        // 重置
        emptyData() {
            this.searchVal = {};
        },
        handleClose() {
            this.drawerAdd = false;
            this.drawerUpdate = false;
        },
        handleDialogClose() {
            this.dialogAddPro = false;
            this.searchVal.name = '';
        },
        // 账户启用、停用数
        totalData() {
            this.allLaunchBriefProduct = 0;
            this.allLaunchBriefProductRead = 0;
            let data = {
                param: {},
            };

            totalData(data).then((res) => {
                if (res.code == 200) {
                    res.data.launchBriefProductList.forEach((item) => {
                        this.allLaunchBriefProduct += item.total;
                        this.allLaunchBriefProductRead += item.openTotal;
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.negative {
    color: #ff7e63;
    font-size: 12px;
}
.just {
    font-size: 12px;
    color: #2370eb !important;
}
.manage_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        .zxiaohao {
            font-weight: 600;
            i {
                background-color: #ddf8ff;
                color: #597ef7;
            }
        }
        .qiyong {
            font-weight: 600;
            i {
                background-color: #dae0fc;
                color: #4b68ee;
            }
        }
        .tingyong {
            font-weight: 600;
            i {
                background-color: #fbf4f7;
                color: #e41f3f;
            }
        }
        span {
            font-weight: 500;
            font-size: 14px;
            margin: 0 16px;
            i {
                width: 24px;
                height: 24px;
                display: inline-block;
                text-align: center;
                line-height: 24px;
                vertical-align: -1px;
                font-weight: normal;
                border-radius: 4px;
            }
        }
        .search_one {
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dashed #e5e5e5;
            .left {
                p {
                    display: inline-block;
                    margin-right: 20px;
                    font-size: 14px;
                    font-weight: 500;
                }
                // button {
                //   background: #2370EB;
                //   border-radius: 2px;
                //   border-color: #2370EB;
                //   margin-right: 16px;
                // }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }

        .detail_item {
            width: 100%;
            margin-bottom: 15px;
            box-sizing: border-box;

            .bg {
                background: linear-gradient(180deg, #ffffff 0%, #faf9ff 100%);
                padding: 15px;

                > span:first-child {
                    background-color: #2370eb;
                    padding: 3px 25px;
                    min-width: 100px;
                    text-align: center;
                    display: inline-block;
                    border-radius: 4px;
                    color: #ffff;
                }
            }
            .btn {
                float: right;
                background-color: #2370eb;
                padding: 0px 10px;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                border-radius: 4px;
                border: 1px solid #e5e5e5;
            }
        }
    }
    .table-title {
        background: #fff;
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
    .table {
        flex: 1;
    }
    .search-two {
        margin-bottom: 10px;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-pagination {
            padding: 10px 5px;
        }
    }
    .page_box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px dashed #e5e5e5;
        .el-button {
            background-color: transparent;
            border: 1px solid #2370eb;
            color: #2370eb;
            font-size: 12px;
            padding: 3px 10px;
            height: 25px;
            cursor: pointer;
            margin: 0 40px 0 20px;
        }
    }
    .el-icon-sort-down {
        transform: rotateX(180deg) rotateZ(270deg);
        margin-left: 5px;
        color: #c0c4cc;
        vertical-align: middle;
    }
    .money_input {
        display: inline-block;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        /deep/ .el-input__inner {
            border: none;
            padding: 0 15px;
        }
    }
}
.search-one {
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .left {
        p {
            display: inline-block;
            margin-right: 20px;
            font-size: 14px;
            font-weight: 500;
        }
        button {
            background: #2370eb;
            border-radius: 2px;
            border-color: #2370eb;
            margin-right: 16px;
        }
        .el-range-editor--small.el-input__inner {
            vertical-align: middle;
            margin-left: 19px;
        }
    }
    .right {
        background: #2370eb;
        border-radius: 2px;
        border-color: #2370eb;
        margin-right: 16px;
    }
}
.dialog_add_customer {
    .el-dialog__footer {
        padding: 0;
    }
    .dialog_add_customer_body {
        width: 65%;
        margin: 60px auto 150px;
    }
}
.detail_list {
    background-color: #f0f2f4;
}
.main_box {
    position: relative;
    display: flex;
    height: 300px;
    width: 100%;
    .main {
        width: 230px;
        height: 230px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .center_data {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 200px;
        height: 200px;
        background: transparent;
        box-shadow: 0px 5px 16px 0px rgba(90, 117, 248, 0.2);
        border-radius: 50%;
        .inside_circle {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 140px;
            height: 140px;
            background: transparent;
            box-shadow: inset 0px 5px 16px 0px rgba(90, 117, 248, 0.2);
            border-radius: 50%;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            color: #ff7e63;
            font-weight: 500;
            > i {
                font-size: 20px;
            }
            > span {
                font-size: 12px;
                color: #999;
                margin: 0;
                i {
                    width: auto;
                    height: auto;
                }
            }
            > p {
                font-size: 14px;
                color: #333;
                margin-bottom: 5px;
                span {
                    color: #2370eb;
                    font-size: 18px;
                    display: block;
                    margin-bottom: 3px;
                }
            }
        }
    }
    .clue_data_box {
        margin: 30px 0 0 300px;
        display: flex;
        justify-content: space-around;
        > div {
            height: 100%;
            display: flex;
            flex-direction: column;
            > div {
                display: flex;
                align-items: center;
                height: 47px;
                p {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-content: space-around;
                    justify-content: space-around;
                }
                span {
                    display: flex;
                    font-size: 16px;
                    em {
                        width: 200px;
                        font-style: normal;

                        font-weight: 500;
                    }
                }

                span:first-child {
                    // margin-bottom: 5px;
                }
                span:last-child {
                    em {
                        font-size: 12px;
                        color: #666;
                    }
                }
                .clue_data {
                    font-size: 16px;
                    em {
                        width: 200px;
                        font-style: normal;
                        color: #333 !important;
                        font-size: 16px !important;
                        font-weight: 500;
                    }
                }
                p {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }
                i {
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 32px;
                }
                .icon-quedingzhanshi {
                    background: #fff1e2;
                    color: #fda537;
                }
                .icon-icon-dianjifangkuang {
                    background: #e5eafc;
                    color: #536ff8;
                }
                .icon-xitongtuisong {
                    background-color: #dff5fe;
                    color: #51bee9;
                }
                .icon-shuyi_jinqian-jiage {
                    background-color: #e3f8f3;
                    color: #49ddaf;
                }
                .icon-xiansuo1 {
                    background-color: #ffece5;
                    color: #ff7e63;
                }
                .icon-icon03 {
                    background-color: #fff1e2;
                    color: #fda537;
                }
                .icon-xiansuo-daoyinxuanzhong {
                    background-color: #e5eafc;
                    color: #536ff8;
                }
                .icon-chukoutuishui_huanhuichengben {
                    background-color: #dff5fe;
                    color: #51bee9;
                    font-size: 25px;
                }
            }
            div:nth-child(2) {
                margin: 20px 0;
            }
            div:nth-child(3) {
                margin-bottom: 20px;
            }
        }
        .line {
            width: 1px;
            height: 167px;
            margin: 30px 100px 0;

            border-left: 1px dashed #e5e5e5;
        }
    }
}

.detail_item {
    .channel_list {
        width: 100%;
        background-color: #fff;
        .channel_item {
            height: 74px;
            width: 100%;
            display: flex;
            border-bottom: 1px dashed #e5e5e5;
            align-items: center;
            justify-content: space-between;
            img {
                width: 25px;
                height: 25px;
                margin-top: 5px;
            }
            > span {
                white-space: nowrap;
                line-height: 74px;
                font-size: 16px;
                font-weight: 500;
                position: relative;
                display: inline-block;
                width: 50px;
                text-align: center;
            }

            .first {
                position: relative;
            }
            .first:after {
                content: '';
                position: absolute;
                left: -10px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                border-left: 1px dashed #e5e5e5;
                height: 50px;
            }
            > div {
                display: flex;
                align-items: center;
                width: 17.5%;
                p {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-content: space-around;
                    height: 40px;
                    span {
                        margin-left: 12px;
                        margin-right: 0;
                    }
                    span:first-child {
                        font-size: 16px;
                        color: #333;
                        font-weight: 500;
                    }
                    span:last-child {
                        font-size: 12px;
                        em {
                            color: #666;
                        }
                    }
                    .clue_data {
                        font-size: 16px !important;
                        em {
                            // width: 200px;
                            width: 80px;
                            font-style: normal;
                            color: #333 !important;
                            font-size: 16px !important;
                            font-weight: 500;
                        }
                    }

                    em {
                        font-weight: 500;
                        font-style: normal;
                        display: inline-block;
                        width: 80px;
                    }
                }
            }
            .el-button {
                background-color: transparent;
                border: 1px solid #2370eb;
                color: #2370eb;
                font-size: 12px;
                padding: 3px 10px;
                height: 25px;
                cursor: pointer;
                margin: 0 40px 0 20px;
            }
        }
    }
}
.negative {
    color: #ff7e63;
    font-size: 12px !important;
}
.just {
    font-size: 12px !important;
    color: #2370eb;
}
.table_box {
}

.hoverBtn {
    background: transparent;
    color: #2370eb;
    border-color: #2370eb;
    font-size: 12px;
    padding: 0px 10px;
}
.hoverBtn:hover {
    background-color: #2370eb;
    color: #fff;
}
</style>
