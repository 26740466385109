<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="账户名称" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <!-- <i class="el-icon-success"></i> -->

                    <span>
                        {{ scope.row.account }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="广告类型"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    {{ $tableDataHandle.launchAdvertType(scope.row.adType) }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="views"
                label="展现"
                width="60"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.views }}次</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="clicks"
                label="点击"
                width="60"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.clicks }}次</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="点击率"
                show-overflow-tooltip
                width="100"
            >
                <template slot-scope="scope">
                    <span>{{
                        ((scope.row.clicks / scope.row.views) * 100).toFixed(
                            2
                        ) + '%'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="点击均价"
                show-overflow-tooltip
                width="100"
            >
                <template slot-scope="scope">
                    <span
                        >{{
                            (scope.row.costAmount / scope.row.clicks).toFixed(
                                2
                            )
                        }}元</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="costAmount"
                :label="dataChoice + '消耗'"
                show-overflow-tooltip
                width="100"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.costAmount }}元</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="余额" width="100" show-overflow-tooltip>
                <template slot="header">
                    余额
                    <i
                        class="el-icon-refresh-right"
                        @click="
                            updateAmount({
                                productId: tableData[0].productId,
                                channelType: tableData[0].channelType,
                            })
                        "
                    ></i>
                </template>
                <template slot-scope="scope">
                    <span>
                        <i
                            :class="
                                scope.row.refreshBalanceStatus == 1
                                    ? 'el-icon-success'
                                    : 'el-icon-warning'
                            "
                        ></i>
                        {{ scope.row.balanceAmount }}元
                        <i
                            class="el-icon-refresh-right"
                            @click="updateAmount(scope.row)"
                        ></i>
                    </span>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            tableRowId: '',
            searchDate: [],
            dataChoice: '',
            adminId: sessionStorage.getItem('adminId'),
            userType: sessionStorage.getItem('userType'),
            departmentId: sessionStorage.getItem('departmentId'),
        };
    },
    methods: {
        getData(data, dataChoice) {
            this.tableData = data;
            this.dataChoice = dataChoice;
        },
        updateAmount(data) {
            // // console.log(data);
            this.$emit('updateAmount', data);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/ .el-table__body-wrapper {
    height: auto !important;
}
.table-box {
    width: 100%;
    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(6) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 16px;
                background: #ff9385;
                border-radius: 63px 63px 63px 1px;
                font-size: 12px;
                font-style: normal;
                text-align: center;
                line-height: 16px;
                color: #fff;
            }
        }
    }
}
.el-icon-refresh-right {
    color: #216ce5;
    cursor: pointer;
}
/deep/ th:nth-child(8) .cell {
    padding-left: 30px;
}
.el-icon-success {
    color: #2370eb;
}
.el-icon-warning {
    color: red;
}
</style>
