<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="账户名称" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <!-- <i class="el-icon-success"></i> -->
                    <span>
                        {{ scope.row.account }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="productName"
                label="产品名称"
                show-overflow-tooltip
                width="80"
            ></el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="投放渠道"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.launchType(scope.row.channelType)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="广告类型"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    {{ $tableDataHandle.launchAdvertType(scope.row.adType) }}
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="views"
                width="60"
                label="展现"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.views }}次</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="clicks"
                width="60"
                label="点击"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.clicks }}次</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="点击率"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <span>
                        {{ (scope.row.clicks / scope.row.views).toFixed(2) }}%
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="点击均价"
                show-overflow-tooltip
                width="100"
            >
                <template slot-scope="scope">
                    <span
                        >{{
                            (scope.row.costAmount / scope.row.clicks).toFixed(
                                2
                            )
                        }}元</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="costAmount"
                :label="dataChoice + '消耗'"
                show-overflow-tooltip
                width="100"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.costAmount }}元</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="余额" width="100" show-overflow-tooltip>
                <template slot="header">
                    余额
                    <i
                        class="el-icon-refresh-right"
                        @click="updateAmount({})"
                    ></i>
                </template>
                <template slot-scope="scope">
                    <span>
                        <i
                            :class="
                                scope.row.refreshBalanceStatus == 1
                                    ? 'el-icon-success'
                                    : 'el-icon-warning'
                            "
                        ></i>
                        {{ scope.row.balanceAmount }}元
                        <i
                            class="el-icon-refresh-right"
                            @click="updateAmount(scope.row)"
                        ></i>
                    </span>
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { launchDataTableList } from '@/api/user/launch/data.js';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            tableRowId: '',
            dataChoice: '',
            searchDate: [],
            adminId: sessionStorage.getItem('adminId'),
            userType: sessionStorage.getItem('userType'),
            departmentId: sessionStorage.getItem('departmentId'),
        };
    },
    methods: {
        // 获取列表
        getData(
            launchDO,
            currentPage,
            pagesize,
            dataChoice,
            dataChoice2,
            timeDate
        ) {
            this.dataChoice = dataChoice;
            let data = {
                param: {},
                pageNum: currentPage,
                pageSize: pagesize,
            };
            if (launchDO.productId) {
                data.param.productId = launchDO.productId;
            }
            if (launchDO.channelType) {
                data.param.channelType = launchDO.channelType;
            }
            let date = new Date();
            if (dataChoice == '今日') {
                data.param.startDate = this.$searchTime.getNextDate(date, 0);
                data.param.endDate = this.$searchTime.getNextDate(date, 0);
            }
            if (this.dataChoice == '本周') {
                data.param.startDate =
                    this.$searchTime.DateUtil.getStartDayOfWeek();
                data.param.endDate =
                    this.$searchTime.DateUtil.getEndDayOfWeek();
            }
            if (this.dataChoice == '本月') {
                data.param.startDate =
                    this.$searchTime.DateUtil.getStartDayOfMonth();
                data.param.endDate =
                    this.$searchTime.DateUtil.getEndDayOfMonth();
            }
            if (dataChoice2 == '昨日') {
                data.param.startDate = this.$searchTime.getNextDate(date, 1);
                data.param.endDate = this.$searchTime.getNextDate(date, 1);
            }
            if (timeDate) {
                data.param.startDate = timeDate[0];
                data.param.endDate = timeDate[1];
            }
            launchDataTableList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;

                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        updateAmount(data) {
            this.$emit('updateAmount', data);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 288px;

    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(6) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 16px;
                background: #ff9385;
                border-radius: 63px 63px 63px 1px;
                font-size: 12px;
                font-style: normal;
                text-align: center;
                line-height: 16px;
                color: #fff;
            }
        }
    }
}
/deep/ .has-gutter tr th:nth-child(10) .cell {
    padding-left: 30px;
}
.el-icon-success {
    color: #2370eb;
}
.el-icon-warning {
    color: #ffa802;
}
.el-icon-refresh-right {
    color: #216ce5;
    cursor: pointer;
}
</style>
