import http from '@/utils/http.js'

// 投放数据根据产品分
export function launchDataProductList(data) {
     return http({
          url: "/jasoboss/launch/data/list/product",
          method: "post",
          data
     })
}
// 投放数据根据渠道分
export function launchDataChannelList(data) {
     return http({
          url: "/jasoboss/launch/data/list/channel",
          method: "post",
          data
     })
}
// 投放数据列表
export function launchDataTableList(data) {
     return http({
          url: "/jasoboss/launch/data/list",
          method: "post",
          data
     })
}
// 刷新所有数据
export function refresh(data) {
     return http({
          url: "/jasoboss/launch/data/refresh",
          method: "post",
          data
     })
}